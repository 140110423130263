/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/no-array-index-key */
import React, { memo, useState, useCallback } from 'react';
import SingleInput from './SingleInput';

import { makeStyles, createStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles( ( theme ) =>
  createStyles( {
    root: {
      flexGrow: 1
    }
  } )
);

export function CodeInputComponent ( props ) {
  const classes = useStyles();
  const {
    length,
    isNumberInput,
    autoFocus,
    disabled,
    onChangeValue
  } = props;

  const [activeInput, setActiveInput] = useState(0);
  const [wholeValues, setWholeValues] = useState( Array( length ).fill( '' ) );

  const [typingTimeout, setTypingTimeout] = useState( null );

  // Helper to return val from inputs
  const handleCharChange = useCallback(
    ( vals ) => {

      if (typingTimeout) {
        clearTimeout(typingTimeout);
      }

      const wholeValue = vals.join('');

      if ( wholeValue.length === length ) {

        setTypingTimeout( setTimeout( () => {
          onChangeValue(wholeValue);
        }, 1500 ) );

      }

    },
    [length, onChangeValue, typingTimeout],
  );

  // Helper to return value with the right type: 'text' or 'number'
  const getRightValue = useCallback(
    (str) => {
      let changedValue = str;
      if (!isNumberInput) {
        return changedValue;
      }
      return !changedValue || /\d/.test(changedValue) ? changedValue : '';
    },
    [isNumberInput],
  );

  // Change OTP value at focussing input
  const changeCodeAtFocus = useCallback(
    (str) => {
      const updatedwholeValues = [...wholeValues];
      updatedwholeValues[activeInput] = str[0] || '';
      setWholeValues(updatedwholeValues);
      handleCharChange(updatedwholeValues);
    },
    [activeInput, handleCharChange, wholeValues],
  );

  // Focus `inputIndex` input
  const focusInput = useCallback(
    (inputIndex) => {
      const selectedIndex = Math.max(Math.min(length - 1, inputIndex), 0);
      setActiveInput(selectedIndex);
    },
    [length],
  );

  const focusPrevInput = useCallback(() => {
    focusInput(activeInput - 1);
  }, [activeInput, focusInput]);

  const focusNextInput = useCallback(() => {
    focusInput(activeInput + 1);
  }, [activeInput, focusInput]);

  // Handle onFocus input
  const handleOnFocus = useCallback(
    (index) => () => {
      focusInput(index);
    },
    [focusInput],
  );

  // Handle onChange value for each input
  const handleOnChange = useCallback(
    (e) => {
      const val = getRightValue(e.currentTarget.value);
      if (!val) {
        e.preventDefault();
        return;
      }
      changeCodeAtFocus(val);
      focusNextInput();
    },
    [changeCodeAtFocus, focusNextInput, getRightValue],
  );

  // Hanlde onBlur input
  const onBlur = useCallback(() => {
    setActiveInput(-1);
  }, []);

  // Handle onKeyDown input
  const handleOnKeyDown = useCallback(
    (e) => {
      switch (e.key) {
        case 'Backspace':
        case 'Delete': {
          e.preventDefault();
          if (wholeValues[activeInput]) {
            changeCodeAtFocus('');
          } else {
            focusPrevInput();
          }
          break;
        }
        case 'ArrowLeft': {
          e.preventDefault();
          focusPrevInput();
          break;
        }
        case 'ArrowRight': {
          e.preventDefault();
          focusNextInput();
          break;
        }
        case ' ': {
          e.preventDefault();
          break;
        }
        default:
          break;
      }
    },
    [activeInput, changeCodeAtFocus, focusNextInput, focusPrevInput, wholeValues],
  );

  const handleOnPaste = useCallback(
    (e) => {
      e.preventDefault();
      const pastedData = e.clipboardData
        .getData('text/plain')
        .trim()
        .slice(0, length - activeInput)
        .split('');
      if (pastedData) {
        let nextFocusIndex = 0;
        const updatedwholeValues = [...wholeValues];
        updatedwholeValues.forEach((val, index) => {
          if (index >= activeInput) {
            const changedValue = getRightValue(pastedData.shift() || val);
            if (changedValue) {
              updatedwholeValues[index] = changedValue;
              nextFocusIndex = index;
            }
          }
        });
        setWholeValues(updatedwholeValues);
        handleCharChange(updatedwholeValues);
        setActiveInput(Math.min(nextFocusIndex + 1, length - 1));
      }
    },
    [activeInput, getRightValue, length, wholeValues],
  );

  return (
    <Grid container className={classes.root} spacing={2}>
      <Grid item xs={12}>
        <Grid container justify="center" spacing={2}>
        {Array(length)
        .fill('1')
        .map((_, index) => (
          <Grid item key={`SingleInput-${index}`}>
            <SingleInput
              focus={activeInput === index}
              value={wholeValues && wholeValues[index]}
              autoFocus={autoFocus}
              onFocus={handleOnFocus(index)}
              onChange={handleOnChange}
              onKeyDown={handleOnKeyDown}
              onBlur={onBlur}
              onPaste={handleOnPaste}
              disabled={disabled}
            />
          </Grid>
        ))}
        </Grid>
      </Grid>
    </Grid>
  );

}

export default memo( CodeInputComponent );

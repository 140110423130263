import { makeStyles, createStyles } from '@material-ui/core/styles';

import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import Typography from '@material-ui/core/Typography';

import { Fade } from 'react-awesome-reveal';

import WbSunny from '@material-ui/icons/WbSunny';

import useVisibilityAwareness from '../../hooks/useVisibilityAwareness';

const useStyles = makeStyles( ( theme ) =>
  createStyles( {
    rootBox: {
      backgroundColor: '#fff',
      display: 'flex',
      paddingBottom: 10
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center'
    },
    vMiddle: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center'
    },
    row: {
      flexGrow: 1
    }
  } ),
);

function Features ( { slug, activate } ) {

  const classes = useStyles();

  const ref = useVisibilityAwareness( slug, activate );

  return (

    <Box ref={ref} id={slug} className={classes.rootBox} minHeight="100vh">

      <Container className={classes.container}>

        <Grid container className={classes.row} spacing={4}>

          <Grid item xs={12} sm={12} md={12} lg={12} className={classes.vMiddle}>
            <Box textAlign="center">
              <Fade direction="left" delay={500} triggerOnce>
                <Typography variant="h2" gutterBottom>
                  Features
                </Typography>
              </Fade>
              <Fade delay={1000} triggerOnce>
                <img alt="Placeholder" src="https://via.placeholder.com/1000x200.png?text=Placeholder" />
              </Fade>
              <Typography variant="body2" gutterBottom>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean iaculis neque metus, in bibendum quam mollis et.
                </Typography>
            </Box>
          </Grid>
          
        </Grid>

        <br /><br /><br />

        <Grid container className={classes.row} spacing={4}>

          <Grid item xs={12} sm={12} md={12} lg={12} className={classes.vMiddle}>
            <Box textAlign="center">
              <Fade direction="left" delay={500} triggerOnce>
                <Typography variant="h5">
                  Feature 1
                </Typography>
              </Fade>
            </Box>
          </Grid>

          <Grid item sm={12} md={6} lg={6} className={classes.vMiddle}>
            <Box textAlign="justify">
              <Fade delay={1000} triggerOnce>
                <Typography variant="body2" gutterBottom>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean iaculis neque metus, in bibendum quam mollis et. Morbi efficitur tincidunt tellus. Fusce non malesuada magna. Aliquam sem libero, convallis non mi a, aliquam interdum massa. Quisque pulvinar mi ut elit vehicula, non lacinia arcu euismod. Aliquam sodales nibh eleifend, posuere quam id, dignissim leo. Vivamus eu velit non lorem semper lobortis sit amet quis sapien. Duis eget velit nec est lobortis elementum. Fusce lorem neque, congue in leo at, fringilla hendrerit eros. Phasellus vehicula lorem nec purus mattis, eget elementum tortor pellentesque.
                </Typography>
              </Fade>
            </Box>
          </Grid>

          <Grid item sm={12} md={6} lg={6} className={classes.vMiddle}>
            <Box textAlign="justify">
              <Fade delay={1000} triggerOnce>
                <Typography variant="body2" gutterBottom>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean iaculis neque metus, in bibendum quam mollis et. Morbi efficitur tincidunt tellus. Fusce non malesuada magna. Aliquam sem libero, convallis non mi a, aliquam interdum massa. Quisque pulvinar mi ut elit vehicula, non lacinia arcu euismod. Aliquam sodales nibh eleifend, posuere quam id, dignissim leo. Vivamus eu velit non lorem semper lobortis sit amet quis sapien. Duis eget velit nec est lobortis elementum. Fusce lorem neque, congue in leo at, fringilla hendrerit eros. Phasellus vehicula lorem nec purus mattis, eget elementum tortor pellentesque.
                </Typography>
              </Fade>
            </Box>
          </Grid>
          
        </Grid>

        <br /><br /><br />

        <Grid container className={classes.row} spacing={4}>

          <Grid item xs={12} sm={12} md={7} lg={7} className={classes.vMiddle}>
            <Box>
              <Fade delay={1000} triggerOnce>
                <img alt="Placeholder" src="https://via.placeholder.com/1000x600.png?text=Placeholder" />
              </Fade>
            </Box>
          </Grid>

          <Grid item xs={12} sm={12} md={5} lg={5} className={classes.vMiddle}>
            <Box textAlign="justify">
              <Fade delay={1000} triggerOnce>
                <Typography variant="h5">
                  Feature 2
                </Typography>
                <Typography variant="body2" gutterBottom>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean iaculis neque metus, in bibendum quam mollis et. Morbi efficitur tincidunt tellus. Fusce non malesuada magna. Aliquam sem libero, convallis non mi a, aliquam interdum massa. Quisque pulvinar mi ut elit vehicula, non lacinia arcu euismod. Aliquam sodales nibh eleifend, posuere quam id, dignissim leo. Vivamus eu velit non lorem semper lobortis sit amet quis sapien. Duis eget velit nec est lobortis elementum. Fusce lorem neque, congue in leo at, fringilla hendrerit eros. Phasellus vehicula lorem nec purus mattis, eget elementum tortor pellentesque.
                </Typography>
              </Fade>
            </Box>
          </Grid>
          
        </Grid>

        <br /><br /><br />

        <Grid container className={classes.row} spacing={4}>

          <Grid item xs={12} sm={12} md={5} lg={5} className={classes.vMiddle}>
            <Box textAlign="justify">
              <Fade delay={1000} triggerOnce>
                <Typography variant="h5">
                  Feature 3
                </Typography>
                <Typography variant="body2" gutterBottom>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean iaculis neque metus, in bibendum quam mollis et. Morbi efficitur tincidunt tellus. Fusce non malesuada magna. Aliquam sem libero, convallis non mi a, aliquam interdum massa. Quisque pulvinar mi ut elit vehicula, non lacinia arcu euismod. Aliquam sodales nibh eleifend, posuere quam id, dignissim leo. Vivamus eu velit non lorem semper lobortis sit amet quis sapien. Duis eget velit nec est lobortis elementum. Fusce lorem neque, congue in leo at, fringilla hendrerit eros. Phasellus vehicula lorem nec purus mattis, eget elementum tortor pellentesque.
                </Typography>
              </Fade>
            </Box>
          </Grid>

          <Grid item xs={12} sm={12} md={7} lg={7} className={classes.vMiddle}>
            <Box>
              <Fade delay={1000} triggerOnce>
                <img alt="Placeholder" src="https://via.placeholder.com/1000x600.png?text=Placeholder" />
              </Fade>
            </Box>
          </Grid>
          
        </Grid>

        <br /><br /><br />
        <br /><br /><br />

        <Grid container className={classes.row} spacing={4}>

          <Grid item xs={3} sm={3} md={3} lg={3} className={classes.vMiddle}>
            <Box textAlign="center">
              <Fade delay={1000} triggerOnce>
                <WbSunny fontSize="large" />
              </Fade>
              <Fade delay={1500} triggerOnce>
                <Typography variant="body2" gutterBottom>
                  Item
                </Typography>
              </Fade>
            </Box>
          </Grid>
          <Grid item xs={3} sm={3} md={3} lg={3} className={classes.vMiddle}>
            <Box textAlign="center">
              <Fade delay={1000} triggerOnce>
                <WbSunny fontSize="large" />
              </Fade>
              <Fade delay={1500} triggerOnce>
                <Typography variant="body2" gutterBottom>
                  Item
                </Typography>
              </Fade>
            </Box>
          </Grid>
          <Grid item xs={3} sm={3} md={3} lg={3} className={classes.vMiddle}>
            <Box textAlign="center">
              <Fade delay={1000} triggerOnce>
                <WbSunny fontSize="large" />
              </Fade>
              <Fade delay={1500} triggerOnce>
                <Typography variant="body2" gutterBottom>
                  Item
                </Typography>
              </Fade>
            </Box>
          </Grid>
          <Grid item xs={3} sm={3} md={3} lg={3} className={classes.vMiddle}>
            <Box textAlign="center">
              <Fade delay={1000} triggerOnce>
                <WbSunny fontSize="large" />
              </Fade>
              <Fade delay={1500} triggerOnce>
                <Typography variant="body2" gutterBottom>
                  Item
                </Typography>
              </Fade>
            </Box>
          </Grid>
          
        </Grid>

        <br /><br /><br />
        <br /><br /><br />

      </Container>

    </Box>

  );
}

export default Features;

import CssBaseline from '@material-ui/core/CssBaseline';

import { makeStyles, createStyles } from '@material-ui/core/styles';

import Fade from 'react-reveal/Fade';

import Auth from './pages/Auth';

import Content from './pages/Content';

import useAuthStatus from './hooks/useAuthStatus';

import CookiesUsageWarning from './components/CookiesUsageWarning';

import { primary } from './style/colors';

const useStyles = makeStyles( ( theme ) =>
  createStyles( {
    container: {
      backgroundColor: primary
    },
    wrapper: {
      position: 'absolute',
      //paddingLeft: 10,
      //paddingRight: 10,
      top: 0,
      left: 0,
      right: 0,
      height: '100sv'
    }
  } ),
);

function App() {

  const classes = useStyles();

  const { active, activate } = useAuthStatus();

  //Slide direction="down" in={!active} mountOnEnter unmountOnExit appear={false} timeout={1000}
  return ( ///https://www.react-reveal.com/docs/props/
    <div className={classes.container}>
      <Fade top when={!active} mountOnEnter unmountOnExit duration={1000}>
        <div className={classes.wrapper}>
          <Auth onSuccess={activate} />
        </div>
      </Fade>
      <Fade bottom when={active} mountOnEnter unmountOnExit duration={1000}>
        <div className={classes.wrapper}>
          <Content />
        </div>
      </Fade>
    </div>
  );
}

function NormalizedApp () {
  return (
    <>
      <CssBaseline />
      <App />
      <CookiesUsageWarning />
    </>
  );
}

export default NormalizedApp;

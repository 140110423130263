import { useState } from 'react';

import Cookies from 'js-cookie';

const cookieName = 'cookies-accepted';

function readFromCookie () {

  const s = Cookies.get( cookieName );

  if ( s !== undefined ) {
    return true;
  }

  return false;

}

function useCookieConsent () {

  const [allowed, setAllowed] = useState( readFromCookie() );

  const [preventRendering, setPreventRendering] = useState( readFromCookie() ); // this is used to fully return null in the parent component so that the snackbar is not only hidden, but not rendered

  const allow = () => {
    setAllowed( true );
    Cookies.set( cookieName, 'true', { expires: 365 } );
    setTimeout( () => {
      setPreventRendering( true );
    }, 1500 );
  };

  return {
    render: !preventRendering,
    allowed,
    allow
  };

}

export default useCookieConsent;

import React, { useState } from 'react';

import Brand from '../../components/Brand';
import Nav from '../../components/Nav';

import Intro from './Intro';

import WhyUs from './WhyUs';

import Features from './Features';

import Partners from './Partners';

import Footer from './Footer';

const menu = {
  intro: 'Intro',
  whyUs: 'Why Us',
  features: 'Features',
  partners: 'Partners',
  contact: 'Contact'
};

function Content () {

  //const classes = useStyles();

  // https://material-ui.com/components/transitions/
  // https://reactcommunity.org/react-transition-group/
  // https://github.com/dennismorello/react-awesome-reveal

  // https://scrollmagic.io/
  // https://www.react-reveal.com/
  // https://medium.com/snips-ai/make-your-next-microsite-beautifully-readable-with-this-simple-javascript-technique-ffa1a18d6de2

  // https://snipsco.github.io/react-scrolling-color-background/

  const [active, setActive] = useState( 'intro' );

  return (

    <>
      <Brand />
      <Nav items={menu} active={active} />
      <Intro slug="intro" activate={setActive} />
      <WhyUs slug="whyUs" activate={setActive} />
      <Features slug="features" activate={setActive} />
      <Partners slug="partners" activate={setActive} />
      <Footer slug="contact" activate={setActive} />
    </>

  );
}

export default Content;

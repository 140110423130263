/* eslint-disable react/jsx-props-no-spreading */
import React, { memo, useRef, useLayoutEffect } from 'react';
import usePrevious from './usePrevious';

import { makeStyles, createStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';

import { secondary, fancyText } from '../../style/colors';

import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const sizes = {
  w: 100,
  h: 140
}

const useStyles = makeStyles( ( theme ) =>
  createStyles( {
    root: {
      flexGrow: 1
    },
    paper: {
      backgroundColor: secondary,
      height: sizes.h,
      width: sizes.w,
      overflow: 'hidden'
    },
    paperMobile: {
      backgroundColor: secondary,
      height: sizes.h/2,
      width: sizes.w/2,
      overflow: 'hidden'
    },
    input: {
      height: sizes.h,
      width: sizes.w,
      caretColor: 'transparent',
      textAlign: 'center',
      fontSize: 120,
      color: fancyText,
      ':selection': {
        backgroundColor: 'transparent'
      }
    },
    inputMobile: {
      height: sizes.h/2,
      width: sizes.w/2,
      caretColor: 'transparent',
      textAlign: 'center',
      fontSize: 120/2,
      color: fancyText,
      ':selection': {
        backgroundColor: 'transparent'
      }
    },
    nativeInput: {
      textAlign: 'center'
    }
  } ),
);

export function SingleInputComponent ( props ) {
  const theme = useTheme();
  const classes = useStyles();
  const { focus, autoFocus, ...rest } = props;
  const inputRef = useRef( null );
  const prevFocus = usePrevious( !!focus );
  useLayoutEffect( () => {
    if (inputRef.current) {
      if (focus && autoFocus) {
        inputRef.current.focus();
      }
      if (focus && autoFocus && focus !== prevFocus) {
        inputRef.current.focus();
        inputRef.current.select();
      }
    }
  }, [autoFocus, focus, prevFocus] );

  const isMobile = useMediaQuery( theme.breakpoints.down( 'sm' ) ); // if lower than sm

  return (
    <Paper className={isMobile ? classes.paperMobile : classes.paper} elevation={5} variant={focus ? 'outlined' : 'elevation'}>
      <InputBase inputRef={inputRef} className={isMobile ? classes.inputMobile : classes.input} inputProps={{className: classes.nativeInput}} {...rest} />
    </Paper>
  );

}

export default memo( SingleInputComponent );

import { useEffect } from 'react';

import { useInView } from 'react-intersection-observer';

function useVisibilityAwareness ( slug, activate ) {

  const opts = {
    threshold: [
      0.1,
      0.3,
      0.5,
      0.75,
      1
    ],
    delay: 250
  };

  if ( slug === 'intro' ) {
    opts.initialInView = true;
  }

  const { ref, inView } = useInView( opts );

  useEffect( () => {

    if ( inView === true ) {
      activate( slug );
    }
  }, [ inView, activate, slug ] );

  return ref;

}

export default useVisibilityAwareness;

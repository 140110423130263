import { useState } from 'react';

import Cookies from 'js-cookie';

const cookieName = 'is-active-session';

function readFromCookie () {

  const s = Cookies.get( cookieName );

  if ( s !== undefined ) {
    return true;
  }

  return false;

}

function useAuthStatus () {

  const [active, setActive] = useState( readFromCookie() );

  const activate = () => {
    setActive( true );
    Cookies.set( cookieName, 'true' );
  };

  return {
    active,
    activate
  };

}

export default useAuthStatus;

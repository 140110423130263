import React from 'react';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';

import useCookieConsent from '../hooks/useCookieConsent';

function CookiesWarning () {

  const { render, allowed, allow } = useCookieConsent();

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    allow();
  };

  if ( !render ) {
    return null;
  }

  return (
    <div>
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={!allowed}
        onClose={handleClose}
        message='This website uses Cookies to offer you the current experience.'
        action={
          <React.Fragment>
            <Button color="inherit" size="small" onClick={handleClose}>
              Accept
            </Button>
          </React.Fragment>
        }
    />
    </div>
  );

}

export default CookiesWarning;

import { makeStyles, createStyles } from '@material-ui/core/styles';

import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import logo from '../assets/logo.svg';

const useStyles = makeStyles( ( theme ) =>
  createStyles( {
    root: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0
    }
  } ),
);

function Brand () { return null;

  const classes = useStyles();

  return (

    <div className={classes.root}>

      <Box>

        <Container>

          <Grid container className={classes.row} spacing={0}>
            <Grid item sm={6} md={3} lg={2} xl={1}>
              <img src={logo} alt="Areascan Logo" />
            </Grid>
          </Grid>

        </Container>

      </Box>

    </div>

  );
}

export default Brand;

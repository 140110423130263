import { makeStyles, createStyles } from '@material-ui/core/styles';

import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import Typography from '@material-ui/core/Typography';

import { Fade } from 'react-awesome-reveal';

import useVisibilityAwareness from '../../hooks/useVisibilityAwareness';

const useStyles = makeStyles( ( theme ) =>
  createStyles( {
    rootBox: {
      backgroundColor: '#fff',
      display: 'flex',
      paddingBottom: 10
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center'
    },
    vMiddle: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center'
    },
    row: {
      flexGrow: 1
    }
  } ),
);

function Partners ( { slug, activate } ) {

  const classes = useStyles();

  const ref = useVisibilityAwareness( slug, activate );

  return (

    <Box ref={ref} id={slug} className={classes.rootBox} minHeight="100vh">

      <Container className={classes.container}>

        <Grid container className={classes.row} spacing={4}>

          <Grid item sm={12} md={12} lg={12} className={classes.vMiddle}>
            <Box textAlign="center">
              <Fade direction="left" delay={500} triggerOnce>
                <Typography variant="h2" gutterBottom>
                  Partners
                </Typography>
              </Fade>
            </Box>
          </Grid>
          
        </Grid>

        <Grid container className={classes.row} spacing={4}>

          <Grid item sm={12} md={4} lg={3} className={classes.vMiddle}>
            <Box textAlign="center">
              <Fade delay={1000} triggerOnce>
                <a href="#">
                  <img alt="Placeholder" src="https://via.placeholder.com/500x300.png?text=Placeholder" width="100%" />
                </a>
              </Fade>
              <Fade direction="left" delay={500} triggerOnce>
                <Typography variant="h6" gutterBottom>
                  Company Name
                </Typography>
              </Fade>
            </Box>
          </Grid>
          <Grid item sm={12} md={4} lg={3} className={classes.vMiddle}>
            <Box textAlign="center">
              <Fade delay={1000} triggerOnce>
                <a href="#">
                  <img alt="Placeholder" src="https://via.placeholder.com/500x300.png?text=Placeholder" width="100%" />
                </a>
              </Fade>
              <Fade direction="left" delay={500} triggerOnce>
                <Typography variant="h6" gutterBottom>
                  Company Name
                </Typography>
              </Fade>
            </Box>
          </Grid>
          <Grid item sm={12} md={4} lg={3} className={classes.vMiddle}>
            <Box textAlign="center">
              <Fade delay={1000} triggerOnce>
                <a href="#">
                  <img alt="Placeholder" src="https://via.placeholder.com/500x300.png?text=Placeholder" width="100%" />
                </a>
              </Fade>
              <Fade direction="left" delay={500} triggerOnce>
                <Typography variant="h6" gutterBottom>
                  Company Name
                </Typography>
              </Fade>
            </Box>
          </Grid>
          <Grid item sm={12} md={4} lg={3} className={classes.vMiddle}>
            <Box textAlign="center">
              <Fade delay={1000} triggerOnce>
                <a href="#">
                  <img alt="Placeholder" src="https://via.placeholder.com/500x300.png?text=Placeholder" width="100%" />
                </a>
              </Fade>
              <Fade direction="left" delay={500} triggerOnce>
                <Typography variant="h6" gutterBottom>
                  Company Name
                </Typography>
              </Fade>
            </Box>
          </Grid>
          <Grid item sm={12} md={4} lg={3} className={classes.vMiddle}>
            <Box textAlign="center">
              <Fade delay={1000} triggerOnce>
                <a href="#">
                  <img alt="Placeholder" src="https://via.placeholder.com/500x300.png?text=Placeholder" width="100%" />
                </a>
              </Fade>
              <Fade direction="left" delay={500} triggerOnce>
                <Typography variant="h6" gutterBottom>
                  Company Name
                </Typography>
              </Fade>
            </Box>
          </Grid>
          <Grid item sm={12} md={4} lg={3} className={classes.vMiddle}>
            <Box textAlign="center">
              <Fade delay={1000} triggerOnce>
                <a href="#">
                  <img alt="Placeholder" src="https://via.placeholder.com/500x300.png?text=Placeholder" width="100%" />
                </a>
              </Fade>
              <Fade direction="left" delay={500} triggerOnce>
                <Typography variant="h6" gutterBottom>
                  Company Name
                </Typography>
              </Fade>
            </Box>
          </Grid>
          <Grid item sm={12} md={4} lg={3} className={classes.vMiddle}>
            <Box textAlign="center">
              <Fade delay={1000} triggerOnce>
                <a href="#">
                  <img alt="Placeholder" src="https://via.placeholder.com/500x300.png?text=Placeholder" width="100%" />
                </a>
              </Fade>
              <Fade direction="left" delay={500} triggerOnce>
                <Typography variant="h6" gutterBottom>
                  Company Name
                </Typography>
              </Fade>
            </Box>
          </Grid>
          <Grid item sm={12} md={4} lg={3} className={classes.vMiddle}>
            <Box textAlign="center">
              <Fade delay={1000} triggerOnce>
                <a href="#">
                  <img alt="Placeholder" src="https://via.placeholder.com/500x300.png?text=Placeholder" width="100%" />
                </a>
              </Fade>
              <Fade direction="left" delay={500} triggerOnce>
                <Typography variant="h6" gutterBottom>
                  Company Name
                </Typography>
              </Fade>
            </Box>
          </Grid>
          
        </Grid>

      </Container>

    </Box>

  );
}

export default Partners;

import React, { useState, useCallback } from 'react';

import { makeStyles, createStyles } from '@material-ui/core/styles';

import IconButton from '@material-ui/core/IconButton';
import Close from '@material-ui/icons/Close';
import Menu from '@material-ui/icons/Menu';

import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import { primary, white } from '../style/colors';

import Box from '@material-ui/core/Box';

const useStyles = makeStyles( ( theme ) =>
  createStyles( {
    btn: {
      position: 'fixed',
      top: 1,
      right: 1,
      backgroundColor: primary,
      color: white,
      zIndex: 998
    },
    drawer: {
      position: 'fixed',
      top: 1,
      right: 1,
      zIndex: 999
    },
    close: {
      textAlign: 'center'
    }
  } ),
);

function Nav ( { items, active } ) {

  const classes = useStyles();

  const [visible, setVisible] = useState( false );

  const toggle = useCallback( () => {
    setVisible( !visible );
  }, [visible] );

  const navigate = ( id ) => () => {
    const elmnt = document.getElementById( id );
    if ( elmnt ) {
      elmnt.scrollIntoView();
    }
  };

  return (

    <>

      {!visible && <IconButton className={classes.btn} onClick={toggle}>
        <Menu />
      </IconButton>}

      <Drawer className={classes.drawer} variant="persistent" anchor="right" open={visible} onClose={toggle}>
        <Box component="div" className={classes.close}>
          <IconButton component="span" onClick={toggle}>
            <Close />
          </IconButton>
        </Box>
        <List>
          {Object.keys( items ).map( ( item ) => (
            <ListItem key={'nav-'+item} button selected={item===active} onClick={navigate( item )}>
              <ListItemText primary={items[item]} />
            </ListItem>
          ) )}
        </List>
      </Drawer>

    </>

  );
}

export default Nav;
